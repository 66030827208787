import type { SVGProps } from "react";

export function TwitterLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.4844 2.70978C19.7367 3.02131 18.989 3.27053 18.1168 3.33284C18.989 2.8344 19.6121 2.02443 19.9236 1.08985C19.1137 1.58829 18.2414 1.89982 17.3068 2.08673C16.5591 1.27676 15.5 0.77832 14.3785 0.77832C12.1355 0.77832 10.2663 2.58517 10.2663 4.89047C10.2663 5.202 10.3286 5.51352 10.3909 5.82505C6.96413 5.63813 3.97347 4.0182 1.9174 1.52598C1.54357 2.14904 1.35665 2.8344 1.35665 3.58206C1.35665 5.01508 2.10431 6.26119 3.1635 7.00885C2.47814 7.00885 1.85509 6.82193 1.29434 6.51041V6.57271C1.29434 8.56648 2.72737 10.1864 4.59652 10.5603C4.22269 10.6226 3.91117 10.6849 3.53733 10.6849C3.28811 10.6849 3.03889 10.6849 2.78967 10.6226C3.28811 12.2425 4.84575 13.4263 6.59029 13.4886C5.21958 14.6101 3.41272 15.2332 1.48126 15.2332C1.16973 15.2332 0.795902 15.2332 0.484375 15.1708C2.35353 16.2923 4.53422 16.9777 6.83952 16.9777C14.3785 16.9777 18.4906 10.7472 18.4906 5.32661C18.4906 5.13969 18.4906 4.95278 18.4906 4.82817C19.2383 4.20511 19.9236 3.51975 20.4844 2.70978Z"
        fill="currentColor"
      />
    </svg>
  );
}
