import classNames from "classnames";

import { FooterItem } from "./components/FooterItem";

import { BLASTLogo } from "./assets/BLASTLogo";
import { InstagramLogo } from "./assets/InstagramLogo";
import { TikTokLogo } from "./assets/TikTokLogo";
import { TwitterLogo } from "./assets/TwitterLogo";

export function Footer({ className }: { className?: string }) {
  return (
    <div className={classNames("flex w-full flex-col justify-center", className)}>
      <div className="m-auto flex min-h-80 w-full flex-col items-center justify-center gap-10 divide-x-0 divide-y-2 divide-solid divide-neutral py-10 no-underline lg:w-fit lg:py-0">
        <div className="flex flex-col place-items-center gap-5 px-7 text-neutral lg:flex-row lg:place-content-between lg:items-center lg:gap-12">
          <div className="flex flex-col justify-center text-neutral">
            <BLASTLogo className="size-6" />
          </div>
          <FooterItem text="Terms of use" linkTo="https://blast.tv/privacy-policy" />
          <FooterItem isNewPage text="About" linkTo="https://about.blast.tv/" />
          <FooterItem text="Support@blast.tv" linkTo="mailto:support@blast.tv" />
          <FooterItem text="Press" linkTo="https://blast.tv/press" />
          <div className="flex w-full place-content-center items-center justify-between lg:w-fit lg:gap-12">
            <a
              className="no-underline"
              href="https://twitter.com/BLASTtv"
              rel="noreferrer"
              target="_blank"
              aria-label="Open BLAST X account in a new window"
            >
              <div className="text-neutral">
                <TwitterLogo />
              </div>
            </a>
            <a
              href="https://www.instagram.com/blastpremier"
              rel="noreferrer"
              target="_blank"
              aria-label="Open BLAST instagram account in a new window"
            >
              <InstagramLogo className="text-neutral" />
            </a>
            <a
              href="https://www.tiktok.com/@blasttv"
              rel="noreferrer"
              target="_blank"
              aria-label="Open BLAST tiktok account in a new window"
            >
              <TikTokLogo className="text-neutral" />
            </a>
          </div>
        </div>
        <div className="w-full pt-10 text-center">
          <p className="font-style-body-b2 text-neutral">BLAST ApS., Hauser Plads 1, 3., 1127 Copenhagen</p>
        </div>
      </div>
    </div>
  );
}
