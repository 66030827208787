import type { SVGProps } from "react";

export function BLASTLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg aria-hidden="true" viewBox="0 0 26 31" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8233 0.848633L10.8688 7.00646L20.2874 14.3209L13.8233 0.848633ZM12.5626 18.0162L18.367 17.4883L17.4623 15.6024L7.86423 8.14841L2.69927 18.9131L9.03341 18.337L10.875 14.4987L12.5626 18.0162ZM14.4962 20.5399L20.3007 20.0119L25.5 30.8486H0L4.51005 21.4483L10.8442 20.8718L8.66156 25.4215H16.8384L14.4962 20.5399Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
