export function FooterItem({ text, linkTo, isNewPage }: { text: string; linkTo?: string; isNewPage?: boolean }) {
  if (linkTo) {
    return (
      <a
        className="line-clamp-1 text-inherit no-underline"
        href={linkTo}
        rel="noreferrer"
        target={isNewPage ? "_blank" : "_self"}
      >
        <p className="font-style-label-l2 text-inherit">{text}</p>
      </a>
    );
  }

  return <p className="font-style-label-l2 text-inherit">{text}</p>;
}
